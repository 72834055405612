import { isAxiosError } from 'axios'
import { store } from '../../app/store'
import APIClient from '../../apiClient'
import StatusE from '../../types/statusE'
import { setStatus, setError, setResponse, setIncidentDetail } from './slice'
import { IncidentT } from '../../types/incidentT'
import { IncidentResponseT } from '../../types/incidentResponseT'
import { ErrorT, ErrorTypeE } from '../../types/errorT'
import { PeopleT } from '../../types/peopleT'

const apiClient = new APIClient()
const apiInstance = apiClient.getInstance()

export const createIncident = (incident: IncidentT) => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))

  const resultPayload = {
    ...incident,
    people: incident.people.map((person: PeopleT) => {
      const { phoneNumberSuffix, ...restPerson } = person // eslint-disable-line @typescript-eslint/naming-convention
      return {
        ...restPerson,
        phone_number: `${person.phoneNumberSuffix}${person.phone_number}`,
        images: person.images?.map((img: any) => ({ type: img.type, uri: img.uri })),
      }
    }),
  }

  apiInstance
    .post<IncidentResponseT>(`/incident`, resultPayload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      dispatch(setResponse(response.data))
    })
    .catch((error) => {
      if (isAxiosError(error)) {
        dispatch(setError(error.response?.data))
        return
      }
      dispatch(setError({ status: ErrorTypeE.INTERNAL_ERROR }))
    })
}

export const getIncidentDetail = (incidentId: string) => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))
  apiInstance
    .get<IncidentResponseT>(`/incident/${incidentId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      dispatch(setIncidentDetail(response.data.data))
    })
    .catch((error: ErrorT) => {
      dispatch(setError(error))
    })
}
