import axios from 'axios'
import dayjs from 'dayjs'
import { GTOB_URL } from '../constants'

export const formatDate = (stringDate: string, format = 'DD-MM-YYYY') =>
  dayjs(stringDate).format(format).toString()

export const createDateFromString = (stringDate: string) => dayjs(stringDate)

export const getDaysAgoFromADate = (stringDate: string) => dayjs().diff(dayjs(stringDate), 'days')

/**
 *
 * @param array -> Any array of any type
 * @returns -> A boolean that indicates if the array only contains string elements
 */
export const isArrayOfStrings = (array: any[]) =>
  array.every((arrayItem) => typeof arrayItem === 'string')

export const navigateToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const applyMaskValue = (inputValue: string, mask?: string) => {
  let i = 0

  const result = mask?.split('').reduce((current: string, next: string) => {
    if (i >= inputValue.length) return current

    if (next === '9' || next === 'X') {
      current += inputValue[i]
      i += 1
    } else {
      current += next
    }

    return current
  }, '')

  return result
}

export const logout = async () => {
  await axios.get(`https://login.goodtobook.com/oauth/destroy`)
  window.location.replace(GTOB_URL)
}
